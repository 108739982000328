import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import { IProp } from './types'
import View from './view'

export default function queryProject() {
  return (
    <StaticQuery
      query={graphql`
        query ProjectsHomePage {
          allFoundationYaml {
            edges {
              node {
                homepage {
                  projects {
                    title
                    list {
                      titleImage
                      image
                      title
                      description
                      cta
                      ctaLink
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allFoundationYaml }: IProp) => {
        const data = mergeEdges(allFoundationYaml)

        if (!data) {
          return null
        }

        return <View data={data.homepage.projects} />
      }}
    />
  )
}
