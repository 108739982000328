import * as React from 'react'

import { Container } from '../Grid'
import { Box, Flex } from '../Helpers'
import { Image } from '../Image'
import { Title } from '../Typography'
import renderEvents from './card'

interface IProps {
  events: Array<{ [key: string]: any }>
}

export default function renderEventsHub({ events }: IProps) {
  const cols = ['100%', null, '50%']

  if (!events || events.length === 0) {
    return null
  }

  return (
    <Container>
      <Flex alignItems="flex-end" flexWrap="wrap">
        <Box width={cols} pr={['0', '2em']} mb={['0', '5em']}>
          <Title mb="3rem">Upcoming events</Title>
          {renderEvents(events)}
        </Box>
        <Box width={cols} pl={['0', '2em']} mt={['4em', '0']}>
          <Image path="akashaHubEvents" alt="Upcoming events" />
        </Box>
      </Flex>
    </Container>
  )
}
