import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image } from '../../shared/Image'
import Link from '../../shared/Link'
import Parallax from '../../shared/Parallax'
import { BlogText, MainTitle } from '../../shared/Typography'
import { IHero } from './types'

interface IProps {
  data: IHero
}

export default function renderHeroHomepage({ data }: IProps) {
  const cols = ['100%', '50%']
  const padd = ['0', '6em']

  return (
    <Container>
      <Flex
        alignItems="center"
        mb={['7.5em', '7em']}
        mt={['2em', null]}
        flexWrap="wrap"
      >
        <Box width={cols} pr={padd} mt={['2em']} mb="2.5em">
          <MainTitle mb="1rem">{data.title}</MainTitle>
          {data.description.split('\n').map(text => (
            <BlogText mb="1.5rem" color="greyMedium">
              {text}
            </BlogText>
          ))}
          {data.ctaLink && (
            <Link
              aria-label="About us"
              color="blue"
              to={data.ctaLink}
              withIcon="arrow"
            >
              {data.cta}
            </Link>
          )}
        </Box>

        <Box width={cols} pl={['0', '2em']} zIndex={-2}>
          <Parallax force={1.2} offsetComp={40}>
            {(offSet: number) => (
              <Image
                alt={data.title}
                path={data.image}
                style={{
                  transform: `translateY(${offSet}px) translateZ(0)`,
                }}
              />
            )}
          </Parallax>
        </Box>
      </Flex>
    </Container>
  )
}
