import * as React from 'react'

import Hero from '../components/Homepage/Hero'
import Projects from '../components/Homepage/Projects'
import LatestPosts from '../components/LatestPosts'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'
import CalendarApi from '../components/shared/CalendarApi'
import Events from '../components/shared/Events'
import Layout from '../layouts'

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <Projects />
      <LatestPosts message="homepage" />
      <Newsletter />
      <CalendarApi dataSource="general">
        {events => <Events events={events} />}
      </CalendarApi>
    </Layout>
  )
}

export default IndexPage
