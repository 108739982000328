import * as React from 'react'

import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image } from '../../shared/Image'
import Link from '../../shared/Link'
import { Caption, Text, Title } from '../../shared/Typography'
import { IList, IProject } from './types'
//
import { isUrlExternal } from '../../../utils/isUrlExternal'

interface IProps {
  data: IProject
}

export default function renderProjectsHomepage({ data }: IProps) {
  return (
    <Container mb={['3.75em', '10em']}>
      <Box mb="3em" width={['100%', 'calc((100% / 2) - 12px)']}>
        <Title>{data.title}</Title>
      </Box>

      <Flex flexWrap="wrap" justifyContent="space-between">
        {data.list.map(renderList)}
      </Flex>
    </Container>
  )
}

function renderList({
  titleImage,
  image,
  title,
  description,
  cta,
  ctaLink,
}: IList) {
  const COLUMNS = 3
  const cols = ['100%', `calc((100% / ${COLUMNS}) - 12px)`]

  const getArrowTypeIfLinkIsExternalOrInternal = (link: string) => {
    return isUrlExternal(link) ? 'arrowNortheast' : 'arrow'
  }

  return (
    <Card p="1.5em" pb="1.3em" key={title} width={cols} mb="1.5em">
      <Flex alignItems="center">
        <div>
          <Box width="160px" minWidth="160px" display={['none', null, 'block']}>
            <Image path={`projectsTitleImage-${titleImage}`} alt={titleImage} />
          </Box>
          <Box display={['none', null, 'block']}>
            <Caption mt="1em" fontSize="1.25rem">
              {title}
            </Caption>
          </Box>
          {/*  */}
          <Box width="160px" minWidth="160px" display={[null, 'block', 'none']}>
            <Image path={`projectsTitleImage-${titleImage}`} alt={titleImage} />
          </Box>
          <Box display={[null, 'block', 'none']}>
            <Caption mt="1em" fontSize="1.25rem">
              {title}
            </Caption>
          </Box>

          <Text color="greyMedium" mb="1.250em" mt="0.5em">
            {description}
          </Text>

          <Link
            to={ctaLink}
            withIcon={getArrowTypeIfLinkIsExternalOrInternal(ctaLink)}
            color="blue"
            aria-label={title}
          >
            {cta}
          </Link>
          {/*  */}
          <Box
            mt="2em"
            ml="auto"
            mr="auto"
            width="200px"
            minWidth="200px"
            display={['none', null, 'block']}
          >
            <Image path={`projectsImage-${image}`} alt={image} />
          </Box>
        </div>
      </Flex>
    </Card>
  )
}
